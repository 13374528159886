<template>
  <div id="app">
    <header class="blog-header">
      <h1>腾小腾的个人主页</h1>
      <nav>
        <ul>
          <li><a href="#home">首页</a></li>
          <li><a href="#about">关于我</a></li>
          <li><a href="#contact">联系方式</a></li>
        </ul>
      </nav>
    </header>

    <main class="blog-content">
      <article class="blog-post" v-for="post in posts" :key="post.id">
        <h2>{{ post.title }}</h2>
        <p class="post-date">{{ post.date }}</p>
        <p class="post-summary">{{ post.summary }}</p>
        <a href="" class="read-more">阅读全文</a>
      </article>
    </main>

    <footer class="blog-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19001321号</a>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      posts: [
        { id: 1, title: 'Vue.js基础', date: '2023-01-01', summary: '这篇文章介绍了Vue.js的基本概念和使用方法...' },
        { id: 2, title: '为什么要使用静态站点生成器', date: '2023-01-15', summary: '静态站点生成器有很多优点，比如速度快、安全性高...' },
        // 更多博客文章...
      ]
    };
  }
}
</script>

<style scoped>
.blog-header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.blog-header h1 {
  margin: 0;
}

.blog-header nav ul {
  list-style: none;
  padding: 0;
}

.blog-header nav ul li {
  display: inline;
  margin-right: 20px;
}

.blog-header nav a {
  color: white;
  text-decoration: none;
}

.blog-content {
  margin: 40px;
}

.blog-post {
  margin-bottom: 30px;
}

.post-date {
  font-size: 0.9em;
  color: #666;
}

.post-summary {
  color: #333;
}

.read-more {
  display: inline-block;
  margin-top: 10px;
  color: #007BFF;
  text-decoration: none;
}

.read-more:hover {
  text-decoration: underline;
}

.blog-footer {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  margin-top: 50px;
}

.blog-footer a {
  color: #333;
  text-decoration: none;
}

.blog-footer a:hover {
  text-decoration: underline;
}
</style>
